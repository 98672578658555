<template>
  <ion-page>
    <ion-header :translucent="true">
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-menu-button></ion-menu-button>
        </ion-buttons>
        <ion-title>{{ folder }}</ion-title>
      </ion-toolbar>
    </ion-header>

    <ion-content :fullscreen="true">
      <ion-header collapse="condense">
        <ion-toolbar>
          <ion-title size="large">{{ folder }}</ion-title>
        </ion-toolbar>
      </ion-header>

      <div id="container" v-html="content"></div>
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import {
  IonButtons,
  IonContent,
  IonHeader,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar,
} from "@ionic/vue";
import { useRoute } from "vue-router";
import { ref, computed, watch } from "vue";
import axios from "axios";

export default {
  name: "Folder",
  components: {
    IonButtons,
    IonContent,
    IonHeader,
    IonMenuButton,
    IonPage,
    IonTitle,
    IonToolbar,
  },
  setup() {
    const route = useRoute();
    const folder = ref(route.params.id || "Patronato");
    const matchedFolder = computed(() => route.params.id);
    const content = ref("");
    watch(matchedFolder, () => {
      folder.value = matchedFolder.value as string;
    });
    axios
      .get(
        "https://parrocchianoventa.org/Pagine/catechismo-iniziazione-cristiana.html?tmpl=component"
      )
      .then((response: any) => {
        //let self = this;
        const parser = new DOMParser();
        const doc = parser.parseFromString(response.data, "text/html");
        const s = doc.querySelector("div#system>div.item>div.content")
          ?.innerHTML;
        if (s != undefined)
          content.value = s.replaceAll(
            '="/',
            '="https://parrocchianoventa.org/'
          );
        window.console.log("Loaded...");
      });
    return { folder, content };
  },
};
</script>

<style scoped>
ion-menu-button {
  color: var(--ion-color-primary);
}

#container {
  text-align: center;
  position: absolute;
  left: 0;
  right: 0;
  /* top: 50%; */
  /* transform: translateY(-50%); */
}

#container strong {
  font-size: 20px;
  line-height: 26px;
}

#container p {
  font-size: 16px;
  line-height: 22px;
  color: #8c8c8c;
  margin: 0;
}

.iframe-fix {
  -webkit-overflow-scrolling: touch !important;
  overflow: scroll !important;
}

#container a {
  text-decoration: none;
}
</style>